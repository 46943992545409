import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today we will have our free Labor Day workout at 10:30am.  Everyone
is invited so bring a friend!  All other classes and open gym are
cancelled for the day.   After the class all members and their families
are invited to the Barnetts house to swim, cookout and relax!  Email,
call or text Daniel for directions.`}</em></strong></p>
    <p><strong parentName="p">{`Labor Day Partner WOD`}</strong></p>
    <p><em parentName="p">{`(one person working at a time, divide up the work however you’d like)`}</em></p>
    <p>{`2 Lap Run`}</p>
    <p>{`50 T2B`}</p>
    <p>{`100 Walking Lunge Steps`}</p>
    <p>{`50 Calorie Row`}</p>
    <p>{`100 Wall Balls (20/14)`}</p>
    <p>{`50 Partner Burpees`}</p>
    <p>{`100 KBS (53/35)`}</p>
    <p>{`2 Lap Run`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 6 is coming September 15th.  We will accept late
registrations until Sept 8th but you will not get your shirts on the
15th.  If you’d like to compete but don’t have a team we have a signup
sheet by the white board. You can also email us to get on a team. If
you’d like to help judge please add your name to the sheet.  We still
need several more judges.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      